<template>
    <div class="chart-container box1" style="width: 100%" :class="[screenWidth > 800 ? '' : 'box1999']">
        <div class="time-selector">
            <button v-for="period in periods" :class="{ active: selectedPeriod == period }" :key="period" @click="selectPeriod(period)">
                {{ period }}
            </button>
        </div>
        <div class="info-panel" v-if="selectedData">
            <span>时间：{{ selectedData.time }}</span>
            <span>开: {{ $integer(selectedData.open, 6, true) }}</span>
            <span>收: {{ $integer(selectedData.close, 6, true) }}</span>
            <span>高: {{ $integer(selectedData.high, 6, true) }}</span>
            <span>低: {{ $integer(selectedData.low, 6, true) }}</span>
            <span>交易量: {{ $integer(selectedData.volume, 4) || '0' }}</span>
        </div>
        <div ref="klineChart" class="kline-chart"></div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import * as echarts from 'echarts';
import { getKlinedataByEndtime } from '@/api/Trade.js';

export default {
    name: 'KlineChart',
    data() {
        return {
            timeOb: '',
            chart: null,
            data: [],
            selectedPeriod: '1d', // 默认选择的时间周期
            selectedData: null, // 当前选中的数据
            periods: ['1m', '30m', '1h', '4h', '1d', '1w'], // 时间周期选项
            yAxisInterval: 0.0005, // Y 轴增量设置
            yAxisMin: 0.005, // Y 轴最小值
            yAxisMax: 0.02, // Y 轴最大值
            barWidth: '50%', // K 线柱形宽度
            lastPrice: null, // 最后一个数据的价格
        };
    },
    computed: {
        ...mapState(['screenWidth']),
    },
    async mounted() {
        this.chart = echarts.init(this.$refs.klineChart);
        await this.loadData();
        this.renderChart();
        this.setInitialData(); // 设置初始数据为最后一条数据
        this.chart.on('dataZoom', this.updateMarkLine);
        window.addEventListener('resize', this.handleResize); // 监听窗口大小变化
        this.timeOb = setInterval(async () => {
            this.loadData();
        }, 5000);
    },
    beforeDestroy() {
        this.chart.dispose(); // 组件销毁时销毁图表实例
        window.removeEventListener('resize', this.handleResize); // 移除事件监听
        clearInterval(this.timeOb);
    },
    methods: {
        async loadData() {
            const { list, pageCount, pageIndex } = await getKlinedataByEndtime({
                endtime: Date.now(),
                type: this.selectedPeriod, //1m 30m 1h 4h 1d 1w
                pageIndex: 1,
                pageSize: 50,
            });
            let arr = [];
            list.forEach(element => {
                const time = new Date(element.dtime).toLocaleString().slice(0, 15).replace('T', ' ');
                arr.push([time, element.o, element.c, element.h, element.l, element.vol]);
            });
            this.data = arr;
        },
        // 设置初始显示的数据为最后一条数据
        setInitialData() {
            if (this.data.length > 0) {
                const lastData = this.data[this.data.length - 1];
                console.log('lastData', lastData);
                this.selectedData = {
                    open: lastData[1],
                    close: lastData[2],
                    high: lastData[3],
                    low: lastData[4],
                    volume: lastData[5], // 交易量
                    time: lastData[0],
                };
                this.lastPrice = lastData; // 获取最后一个收盘价
                this.setMarkLine(this.lastPrice);
            }
        },
        // 周期切换
        async selectPeriod(period) {
            this.selectedPeriod = period; // 更新选择的时间周期
            await this.loadData(); // 更新数据
            this.setInitialData(); // 更新初始数据为最后一条数据
            this.renderChart(); // 重新渲染图表
        },
        renderChart() {
            const option = {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        label: {
                            formatter: function (params) {
                                // 处理 y 轴的数值显示
                                if (params.axisDimension === 'y') {
                                    return params.value.toFixed(6);
                                }
                                // 处理 x 轴的时间显示
                                if (params.axisDimension === 'x') {
                                    return params.value;
                                }
                            },
                        },
                    },
                    formatter: params => {
                        // params 是一个数组，包含了所有系列的数据
                        if (params.length === 0) return '';
                        const data = params[0].data;
                        this.selectedData = {
                            open: data[1],
                            close: data[2],
                            high: data[3],
                            low: data[4],
                            volume: data[5], // 交易量
                            time: data[6],
                        };
                        // 自定义 tooltip 内容
                        const change = this.$integer(((data[2] - data[1]) / data[1]) * 100, 2);
                        const changeClass = change == 0 ? '' : change > 0 ? 'price_up_tip' : 'price_down_tip'; // 如果开盘价小于收盘价，设置为 bullish
                        return `
                            <div style="color: #d7d7d7; font-size: 12px;width:140px" class="flex-column">
                              <p class=" flex-between ptb-2" style="margin-bottom:0px">
                                <span>时间</span>
                                <span>${data[6]}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>开</span>
                                <span>${this.$integer(data[1], 6)}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>收</span>
                                <span>${this.$integer(data[2], 6)}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>高</span>
                                <span>${this.$integer(data[3], 6)}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>低</span>
                                <span>${this.$integer(data[4], 6)}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>涨跌幅</span>
                                <span  class="${changeClass}">${change == 0 ? '' : change > 0 ? '+' : '-'}${change}</span>
                              </p>
                              <p class="flex-between ptb-2" style="margin-bottom:0px">
                                <span>交易量</span>
                                <span>${this.$integer(data[5], 4)}</span>
                              </p>
                            </div>
                          `; // 使用 HTML 标签自定义内容
                    },
                    backgroundColor: '#333', // 背景颜色
                    borderColor: '#3D3D43', // 边框颜色
                    borderWidth: 1, // 边框宽度
                    padding: 10, // 内边距
                    textStyle: {
                        color: '#FFF', // 字体颜色
                        fontSize: 12, // 字体大小
                    },
                },
                axisPointer: {
                    link: { xAxisIndex: 'all' },
                    label: {
                        backgroundColor: '#777',
                        formatter: params => {
                            return Number(params.value).toFixed(6); // markLine 标签保留四位小数
                        },
                    },
                },
                xAxis: {
                    type: 'category',
                    data: this.data.map(item => item[0]),
                    boundaryGap: true, // 确保设置为 true
                    axisLabel: {
                        formatter: value => {
                            const date = new Date(value);
                            const period = this.selectedPeriod;
                            if (period === '1d' || period === '1w') {
                                // 如果是 1d，显示月-日
                                return `${date.getMonth() + 1}-${date.getDate() < 10 ? '0' + date.getDate() : date.getDate()}`;
                            } else {
                                // 其他时间周期，显示时:分
                                return `${date.getHours()}:${date.getMinutes().toString().padStart(2, '0')}`;
                            }
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    position: 'right',
                    axisLabel: {
                        formatter: value => {
                            return value.toFixed(4); // 默认显示四位小数
                        },
                    },
                    // interval: this.yAxisInterval, // 设置 Y 轴增量
                    // min: this.yAxisMin, // 设置 Y 轴最小值
                    // max: this.yAxisMax, // 设置 Y 轴最大值
                    splitLine: {
                        // show: false, // 不显示网格线
                        lineStyle: {
                            color: '#1e1e21', // 网格线颜色
                            width: 1, // 网格线宽度
                            type: 'dashed',
                        },
                    },
                    axisLine: {
                        show: true, // 显示 Y 轴主线
                        lineStyle: {
                            color: '#909090', // 主线颜色
                            width: 2, // 主线宽度
                        },
                    },
                },
                series: [
                    {
                        name: 'K线',
                        type: 'candlestick',
                        data: this.data.map(item => [item[1], item[2], item[3], item[4], item[5], item[0]]),
                        itemStyle: {
                            color: '#13b785', // 上涨的颜色
                            color0: '#f5475e', // 下跌的颜色
                            borderColor: 'transparent', // 去掉外边框
                            borderColor0: 'transparent', // 去掉外边框
                        },
                        barWidth: this.barWidth, // 设置 K 线柱形宽度
                    },
                ],
                dataZoom: [
                    {
                        type: 'inside', // 内部缩放
                        start: 0, // 初始缩放起始位置
                        end: 100, // 初始缩放结束位置
                    },
                    {
                        type: 'slider', // 滑动条缩放
                        show: false,
                        xAxisIndex: [0],
                        start: 0,
                        end: 100,
                    },
                ],
                grid: {
                    left: '2%', // 左边距
                    right: '5%', // 右边距
                    top: '5%', // 上边距
                    bottom: '5%', // 下边距
                    containLabel: true, // 是否包含坐标轴的标签
                },
            };
            this.chart.setOption(option);
        },
        updateMarkLine() {
            // 获取当前数据窗口的起止位置
            const startEnd = this.chart.getOption().dataZoom[0];
            const start = startEnd.start;
            const end = startEnd.end;

            // 计算当前可见数据的范围
            const dataLen = this.data.length;
            const startIndex = Math.floor((dataLen * start) / 100);
            const endIndex = Math.ceil((dataLen * end) / 100) - 1;

            // 获取可见范围内的最后一个数据
            const lastVisibleValue = this.data[endIndex];
            // const lastVisibleValue = this.data[endIndex][2];
            console.log('lastVisibleValue', lastVisibleValue);
            // 更新 markLine
            this.setMarkLine(lastVisibleValue);
        },
        setMarkLine(lastPrice) {
            // 判断涨跌 颜色选择
            const isUp = Number((lastPrice[2] - lastPrice[1]) / lastPrice[1]) < 0 ? false : true;
            this.chart.setOption({
                series: [
                    {
                        markLine: {
                            silent: true, // 禁止交互
                            symbol: ['none', 'none'], // 不显示两端的标记
                            animation: false, // 禁用动画效果
                            data: [
                                {
                                    yAxis: lastPrice[2], // 使用最后一个数据点的值
                                    label: {
                                        show: true,
                                        position: 'end', // 标签位置在线的末端
                                        // formatter: '{c}', // 显示具体数值
                                        formatter: params => {
                                            return Number(params.value).toFixed(6); // markLine 标签保留四位小数
                                        },
                                        // 文字样式
                                        color: '#fff', // 文字颜色
                                        fontSize: 12, // 文字大小
                                        fontWeight: 'bold', // 文字粗细
                                        fontFamily: 'Arial', // 字体

                                        // 背景样式
                                        backgroundColor: isUp ? '#13b785' : '#f5475e', // 背景色
                                        // borderColor: '#FF0000', // 边框颜色
                                        // borderWidth: 1, // 边框宽度
                                        borderRadius: 4, // 圆角
                                        padding: [4, 8], // 内边距 [上下, 左右]

                                        // 阴影
                                        // shadowColor: 'rgba(0,0,0,0.3)',
                                        // shadowBlur: 3,

                                        // 富文本配置
                                        // rich: {
                                        //     price: {
                                        //         color: '#FF0000',
                                        //         fontSize: 16,
                                        //         fontWeight: 'bold',
                                        //         padding: [0, 4],
                                        //     },
                                        //     unit: {
                                        //         color: '#999',
                                        //         fontSize: 12,
                                        //     },
                                        // },
                                    },
                                    lineStyle: {
                                        color: isUp ? '#13b785' : '#f5475e', // 线的颜色
                                        type: 'dashed', // 线的类型
                                        width: 1, // 线的宽度
                                    },
                                },
                            ],
                        },
                    },
                ],
            });
        },
        handleResize() {
            this.chart.resize(); // 调整图表大小
        },
    },
};
</script>

<style lang="scss" scoped>
.chart-container {
    width: 100%; /* 宽度自适应 */
}

.time-selector {
    padding-bottom: 12px;
    button {
        background: #333;
        padding: 6px 12px;
        border-radius: 4px;
    }
    .active {
        background: #0421df;
        color: #fff;
    }
}
.time-selector button {
    margin: 0 5px;
}

.kline-chart {
    width: 100%;
    height: 600px;
}
.info-panel {
    color: #76808f;
    font-size: 12px;
    display: flex;
    flex-wrap: wrap; /* 允许子元素换行 */
    & > span {
        // margin-right: 20px;
        flex: 1 1 1 1 1 1; /* 子元素的基础宽度为 200px，允许缩放 */
        margin: 4px 6px; /* 子元素之间的间距 */
    }
}
.box1999 {
    padding: 16px 10px !important;
}
.price_down {
    font-size: 18px;
    color: #f5475e;
}
.btn_down {
    background-color: #f5475e;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 6px;
}
.price_up {
    font-size: 18px;
    color: #13b785;
}
.btn_up {
    background-color: #13b785;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 6px;
}
</style>
