<template>
    <div class="all-medal-content2 flex-column">
        <div class="flex mb-12">
            <span class="fs-14">当前价格：</span>
            <span :class="[Number(rate * 100) > 0 ? 'price_up' : 'price_down']">$ {{ $integer(price, 6) }}</span>
            <span class="ml-8" :class="[Number(rate * 100) > 0 ? 'btn_up' : 'btn_down']">{{ Number(rate * 100) > 0 ? '+' : '-' }}{{ $integer(rate, 2, true) }}%</span>
        </div>
        <div class="box1" :class="[screenWidth > 1000 ? 'box222' : 'box22222']">
            <!-- from -->
            <div class="fromToken box3 flex-column">
                <div class="flex-between">
                    <p class="flex">
                        <img src="../../assets/img/tokens/AJ.png" alt="" />
                        <span>AJ</span>
                    </p>
                    <div class="flex-1 flex">
                        <input type="text" v-model="amountIn" @input="changeInputA()" placeholder="0.0" />
                    </div>
                </div>
                <div class="flex-between">
                    <span>{{ lang == 'en' ? 'Balance:' : '余额：' }}{{ fromCoinBalance.balance }}</span>
                    <span class="maxBtn" @click="maxFn">Max</span>
                </div>
            </div>
            <p class="flex" style="height: 10px; justify-content: center; margin-bottom: 0px">
                <img class="exchange" src="../../assets/img/default/exchange.png" alt="" />
            </p>
            <!-- to -->
            <div class="fromToken box3 flex-column">
                <div class="flex-between">
                    <p class="flex">
                        <img src="../../assets/img/tokens/USDT.png" alt="" />
                        <span>USDT</span>
                    </p>
                    <div class="flex-1 flex">
                        <input type="text" disabled v-model="amountOut" placeholder="0.0" />
                    </div>
                </div>
                <div class="flex-between" style="padding-top: 10px">
                    <span>{{ lang == 'en' ? 'Balance:' : '余额：' }}{{ toCoinBalance.balance }}</span>
                </div>
            </div>

            <h5 style="padding-top: 20px; color: #fff; font-size: 13px; display: flex; padding-bottom: 8px">
                <img src="../../assets/img/default/reward.png" alt="" />
                <span style="margin-left: 8px">{{ lang == 'en' ? 'Pool liquidity' : '池子流动性' }}</span>
            </h5>
            <div class="box4" style="font-size: 12px; color: #c2c2c2">
                <p class="flex-between">
                    <span>{{ lang == 'en' ? 'AJ quantity' : 'AJ数量' }}</span>
                    <span>{{ liqlity.liqlityAJ }} AJ</span>
                </p>
                <p class="flex-between">
                    <span>{{ lang == 'en' ? 'USDT quantity' : 'USDT数量' }}</span>
                    <span>{{ liqlity.liqlityUSDT }} USDT</span>
                </p>
            </div>
            <Mbutton class="btnBox" :disabled="btnDisabled" :loading="btnLoading" :value="btnText" @click="swapsellAJTokenFn()" />
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import Mbutton from '@/components/Mbutton.vue';
import { getPoolInfo, swapsellAJToken, getKlinedataByEndtime } from '@/api/Trade.js';
import { getSign } from '@/web3utils/base/index.js';
import { GetKey, Balance } from '@/api/Users.js';
export default {
    data() {
        return {
            fromCoinId: 2,
            toCoinId: 1,
            amountIn: '',
            amountOut: '',
            btnLoading: false,
            liqlity: {}, // 池子流动性
            price: '0',
            rate: '0.001',
            timerObj:null
        };
    },
    components: { Mbutton },
    computed: {
        ...mapState(['screenWidth', 'address', 'info', 'lang', 'coinConfig', 'balance']),
        fromCoinBalance() {
            return this.balance[this.fromCoinId];
        },
        toCoinBalance() {
            return this.balance[this.toCoinId];
        },
        // btnText
        btnText() {
            if (!this.amountIn) return this.lang == 'zh' ? '请输入数量' : 'Please enter an address';
            let a = BigInt(this.$toWei(this.fromCoinBalance.balance, 18)) < BigInt(this.$toWei(this.amountIn, 18));
            if (a) return this.lang == 'zh' ? '余额不足' : 'The balance is insufficient';
            return this.lang == 'zh' ? '兑换' : 'Swap';
        },
        btnDisabled() {
            if (!this.amountIn) return true;
            return false;
        },
    },

    async created() {},
    async mounted() {
        this.init();
        this.timerObj = setInterval(() => {
            this.getPrice();
        }, 3000);
    },
    watch: {},
    methods: {
        maxFn() {
            this.amountIn = this.fromCoinBalance.balance;
            this.changeInputA();
        },
        async init() {
            this.liqlity = (await getPoolInfo()).data;
            await this.getPrice();
            await Balance();
        },
        async getPrice() {
            const { list, pageCount, pageIndex } = await getKlinedataByEndtime({
                endtime: Date.now(),
                type: '1d', //1m 30m 1h 4h 1d 1w
                pageIndex: 1,
                pageSize: 1,
            });
            this.price = list[0].c;
            this.rate = ((list[0].c - list[0].o) / list[0].o) * 100;
        },
        async changeInputA() {
            if (this.amountIn == '') return (this.amountOut = '');
            // 获取的USDT =  USDT流动性  -   ((USDT流动性 * AJ流动性)  / (AJ流动性 + 卖出AJ数量))
            let AJ = BigInt(this.$toWei(this.liqlity.liqlityAJ, 18));
            let USDT = BigInt(this.$toWei(this.liqlity.liqlityUSDT, 18));
            let sellAJ = BigInt(this.$toWei(this.amountIn, 18));
            let a = USDT - (USDT * AJ) / (AJ + sellAJ);
            this.amountOut = this.$fromWei(a, 18);
        },
        async swapsellAJTokenFn() {
            if (!this.amountIn) return;
            let a = BigInt(this.$toWei(this.fromCoinBalance.balance, 18)) < BigInt(this.$toWei(this.amountIn, 18));
            if (a) return;
            this.btnLoading = true;
            this.signData = (await GetKey({ address: this.address })).data;
            let signMsg = await getSign(this.signData);
            if (!signMsg) return (this.btnLoading = false);
            let res = await swapsellAJToken({
                amount: this.amountIn,
                sellcoinid: this.fromCoinId,
                signData: signMsg,
            });
            this.btnLoading = false;
            if (!res.success) return this.$message.error(res.msg);
            this.$message.success(res.msg);
            this.amountIn = '';
            this.amountOut = '';
        },
    },
    beforeDestroy() {
    let that = this;
    clearInterval(that.timerObj);
  }
};
</script>
<style lang="scss" scoped>
.box222 {
    padding: 40px 40px !important;
}
.box22222 {
    padding: 20px 16px !important;
}
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 16px;
}
.box4 {
    border-radius: 15px;
    padding: 16px 16px;
    background: #1b1b1b !important;
}
.fromToken {
    padding: 20px;
    & > div:nth-of-type(1) {
        p {
            min-width: 120px;
            background: #292a2d;
            padding: 10px 16px;
            border-radius: 16px;
            margin-bottom: 0px !important;
            img {
                width: 36px;
                height: 36px;
                margin-right: 8px;
            }
            span {
                font-size: 16px;
            }
        }
        & > div {
            justify-content: end;
            input {
                width: calc(100% - 20px);
                font-size: 20px;
                text-align: right;
                background: unset;
                outline: none;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    & > div:nth-of-type(2) {
        padding-top: 8px;
        .maxBtn {
            cursor: pointer;
            padding: 8px 16px;
            border-radius: 30px;
            background-color: #0421df;
        }
    }
}
.exchange {
    width: 42px;
    height: 42px;
}
.btnBox {
    margin-top: 40px;
}
.poolInfo {
    h5 {
        color: #fff;
    }
}
.price_down {
    font-size: 18px;
    color: #f5475e;
}
.btn_down {
    background-color: #f5475e;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 6px;
}
.price_up {
    font-size: 18px;
    color: #13b785;
}
.btn_up {
    background-color: #13b785;
    color: #fff;
    font-size: 12px;
    border-radius: 4px;
    padding: 2px 6px;
}
</style>
