<template>
    <div class="all-medal-content2 flex-column">
        <div class="flex-1 teamList">
            <div class="flex-between">
                <p class="flex" style="margin-bottom: 0px;">
                    <img src="../../assets/img/stack/amount.png" alt="" />
                    <span class="ml-12">{{ lang == 'en' ? 'Trade Detail' : '交易明细' }}</span>
                </p>
                <custom-pagination v-if="totalCount" :totalItems="totalCount" :itemsPerPage="pageSize" @page-change="onChange" />
            </div>
            <ul v-if="arrList.length > 0" class="box1 mt-20 grid-container3" :class="[screenWidth > 900 ? '' : 'mb12']">
                <li>
                    <span>{{ lang == 'en' ? 'Address' : '地址' }}</span>
                    <span>{{ lang == 'en' ? 'Buy/Sell' : '买/卖' }}</span>
                    <span>{{ lang == 'en' ? 'Trading volume' : '交易数量' }}</span>
                    <span>{{ lang == 'en' ? 'Transactions' : '交易额' }}</span>
                    <span>{{ lang == 'en' ? 'Fee' : '手续费' }}</span>
                    <span>{{ lang == 'en' ? 'Price' : '交易单价' }}</span>
                    <span>{{ lang == 'en' ? 'Time' : '交易时间' }}</span>
                </li>
                <li class="" v-for="item in arrList">
                    <span>{{ item.useraddress | addressF}}</span>
                    <span>
                        <em class="sell" v-if="item.type==1">{{ lang == 'en' ? 'Sell' : '出售' }}</em>
                        <em class="buy" v-if="item.type==2">{{ lang == 'en' ? 'Buy' : '购买' }}</em>
                    </span>
                    <span>{{ $integer(item.costa,4)}} AJ</span>
                    <span>{{ $integer(item.getb,4)}} U</span>
                    <span>{{ $integer(item.fee,4)}} U</span>
                    <span>{{ $integer((item.getb+item.fee)/item.costa,4)}} U</span>  
                    <span>{{ item.ctime.replace('T',' ')}}</span>
                </li>
            </ul>
            <empty :data="arrList" />
        </div>
    </div>
</template>
<script>
import CustomPagination from '@/components/CustomPagination';
import empty from '@/components/empty';
import { mapState } from 'vuex';
import { getTradeList } from '@/api/Trade.js';
export default {
    data() {
        return {
            arrList: [],
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
        };
    },
    components: { empty, CustomPagination },
    filters: {
        addressF(v) {
            if (!v) {
                return '-';
            }
            return v.replace(/^([A-Za-z0-9]{5})[A-Za-z0-9]{33}([A-Za-z0-9]{4})$/, '$1…$2');
        },
    },
    computed: {
        ...mapState(['screenWidth', 'address', 'info', 'lang']),
    },

    async created() {},
    async mounted() {
        this.init();
    },
    watch: {},
    methods: {
        async onChange(e) {
            this.pageIndex = e;
            this.getTeamList();
        },
        async init() {
            this.getTeamList();
        },
        async getTeamList() {
            let res = await getTradeList({
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            });
            this.totalCount = res.totalCount;
            this.arrList = [];
            this.arrList = res.list;
        },
    },
};
</script>
<style lang="scss" scoped>
.all-medal-content2 {
    width: 100%;
    flex: 1;
    display: flex;
    margin: 0px 0px !important;
   
}
.box3 {
    background: #1a1a1d;
    border-radius: 15px;
    padding: 16px 20px;
    & > div:nth-of-type(1) {
        border-bottom: 1px solid #313131;
    }
}
.mb12 {
    row-gap: 12px !important;
}
.grid-container2 {
    row-gap: 12px !important;
    grid-template-columns: repeat(1, 1fr) !important;
}
.grid-container3 {
    padding: 0px !important;
    overflow-x: auto;
    width: 100%;
   overflow-x: auto;
    li {
        min-width: 800px;
        display: grid;
        // grid-template-columns: repeat(1, 1fr);
        grid-template-columns: 100px 80px 1fr 1fr 100px 1fr 160px;
        padding: 16px 16px;
        span{
            padding: 0px 4px;
        }
    }
    &>li:nth-of-type(1){
        background-color: #1a1a1d;
    }
    li+li{
        border-bottom: 1px solid #3D3D43;
    }
}
.grid-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
}
.sell{
    font-style: normal;
    color: #fff;
    background-color: #f5475e;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
}
.buy{
    font-style: normal;
    color: #fff;
    background-color: #13b785;
    padding: 3px 8px;
    border-radius: 4px;
    font-size: 12px;
}
</style>
